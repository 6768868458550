import React from 'react'
import Card from 'react-bootstrap/Card'

const Facebook = () => {
    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">FIND US ON FACEBOOK</Card.Header>
            <Card.Body>
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Franreincarnation&tabs=timeline&width=600&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1037609993084869" title="Facebook Page" width="600" height="400" style={{border:"none" ,overflow:"hidden"}} scrolling="no" frameBorder="0" allow="encrypted-media"></iframe>
            </Card.Body>
        </Card>
    )
}

export default Facebook
