import React from 'react'

const Footer = () => {
    return (
        <div className="container footer ran-footer">
               © 2021 - <b><a href="https://www.facebook.com/ranreincarnation" rel="noreferrer" target="_blank">RAN Online : Reincarnation </a></b> | Powered by <b><a href="https://www.facebook.com/khleasec" rel="noreferrer" target="_blank">KHLEA</a></b>
        </div>
    )
}

export default Footer
